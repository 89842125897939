






























































import { defineComponent, PropType } from '@vue/composition-api'

interface FlexibleDidYouKnow {
  htmltext: string
  label: string
}

export default defineComponent({
  name: 'FlexibleDidYouKnow',
  components: {},
  props: {
    content: {
      type: Object as PropType<FlexibleDidYouKnow>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
